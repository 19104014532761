.QBlock2 {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    height: 75px;
}
.QBlock {
    position: relative;
    width: 80%;
    margin-left: auto;
    border-radius: 100px;
    margin-right: auto;
    height: 75px;
}
.QWrapper {
    width: 100%;
}
.wrapperMargin {
    margin-top: 30px;
}
.selected {
    background-color: #00A3BB;
    color: #ffffffff;
}
.notSelected {
    background-color: #ffffff;
    color: #00A3BB;
}
.GoodAnswer {
    background-color: #88BE75;
    color: #ffffffff;
}
.percentR {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: #036573;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    color: white;
}
.Centered {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}
.textName {
    width: 50%;
    font-size: 30px;
    text-align: center;
    color:#036573;
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.Centered2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}
.CenteredQuestion {
    position: absolute;
    left: 48%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
}
.Qtext {
    text-align: center;
    width: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    white-space: pre-wrap;
    font-size: 20px;
}
.QPercentText {
    text-align: center;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.Title {
    font-size: 28px;
    color: white;
    width: 85%;
}
.ButtonQ {
    width: 250px;
    background-color: #00A3BB;
    color:white;
    font-size: 27px;
    margin-left: auto;
    margin-right: auto;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 50px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.subText {
    font-size: 18px;
    color: white;
}

.NumberPodium {
    width: 55px;
    text-align: center;
    background-color: #036573;
    left:5%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 50px;
    color: white;
    border-radius: 30px;

}
.NumberPodium2 {
    width: 50px;
    text-align: center;
    background-color: #036573;
    left:5%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 45px;
    color: white;
    border-radius: 30px;

}
.NumberPodium3 {
    width: 45px;
    text-align: center;
    background-color: #036573;
    left:5%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 40px;
    color: white;
    border-radius: 30px;

}
.PodiumBlock {
    display: inline-block;
}
.TextPodium {
    font-size: 50px;
    color: #00A3BB;
    position: absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 30px;
}
.TextPodium2 {
    font-size: 45px;
    color: #00A3BB;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 80%;
    border-radius: 30px;
}
.TextPodium3 {
    font-size: 40px;
    color: #00A3BB;
    position: absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 30px;
}
.PodiumWrapper {
    width: 95%;
    background-color: white;
    height: 75px;
    position: relative;
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
}
.PodiumWrapper2 {
    width: 80%;
    background-color: white;
    height: 70px;
    position: relative;
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
}
.PodiumWrapper3 {
    width: 65%;
    background-color: white;
    height: 65px;
    position: relative;
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
}
.PodiumBlockWrapper {
    position: absolute;
    left: 50%;
    top: 45%;
    width: 100%;
    transform: translate(-50%,-50%);
}
.podiumStep {
    margin-top: 20px;
}
.textCountdown {
    font-family: Arial, Helvetica, sans-serif;
    color:white;
    font-size: 30px;
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
.errorModal {
    position: absolute;
    z-index: 5;
    padding-top: 75px;
    padding-bottom: 75px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 30px;
    background-color: #c00000;
    color: white;
    top:75px;
    left: 50%;
    transform: translate(-50%,-50%);
}
.CommentaireQ {
    position: absolute;
    left:50%;
    transform: translate(-50%,-50%);
    color: white;
    font-size: 18px;
    margin-top: 50px;
    white-space: pre-wrap;
    width: 100%;
}